import React, { useState, useEffect, useContext } from 'react';


import SimpleTable from '../../components/tables/simpleTable';
import { ImportOutlined } from '@ant-design/icons';
import TrashModal from '../../components/modals/trashModal';
import RecoverModal from '../../components/modals/recoverModal';
import AddModal from '../../components/modals/addModal';
import EditModal from '../../components/modals/editModal';
import DeleteModal from '../../components/modals/deleteModal';
import { FaFileExcel } from "react-icons/fa";
import SearchFilter from '../../components/filters/search';
import CustomButton from '../../components/buttons/customButton';
import IsActiveFilter from '../../components/filters/isActive';
import AddButton from '../../components/buttons/addButton';
import DataFilter from '../../components/filters/dataFilter';
import DatePicker from '../../components/filters/datePicker';

import { FaPlus } from "react-icons/fa";

import Loader from '../../components/loader/loader';
import { numberFormatter, totalFormatter } from '../../components/formatters/numberFormatter';
import { dateFormatter } from '../../dynamic/dateFormatter';
import { clientOrderColumnDefs } from '../../components/tables/columnDefs';
import toast from 'react-hot-toast';

import { Space, Input, Tag, Button, Tabs, Upload  } from 'antd';


import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';


import { FiEdit } from "react-icons/fi";
import { FcUndo, FcViewDetails, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { IoBarcodeOutline } from "react-icons/io5";
import { FcPlus } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";

import { DataContext } from '../../init/getData';

import CompoundProduct from './forms/compound';
import SimpleProduct from './forms/simple';
import SerializedProduct from './forms/serialized';
import ProductionProduct from './forms/production';



function Product(props) {

  let { } = props;


  const { userData, settingData, serverApi, api, refCategory, categoryData, refProductData, productData } = useContext(DataContext);
  const userRole = userData.role;
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCompound, setIsLoadingCompound] = useState(true);
  const [data, setData] = useState([]);
  const createdBy = parseInt(userData.id);



  useEffect(() => {
    const fetchData = async () => {
      try {
        await refCategory();
        setIsLoading(false);
      } catch (error) {
        console.error('Error refreshing data Contact Support');
      }
    };
    fetchData();
  }, []);


  // get data for compound
  const getProductsForCompound = async () => {
    try {
      setIsLoadingCompound(true);
      await refProductData();
      setIsLoadingCompound(false);
    } catch (error) {
      console.error('Error refreshing data Contact Support');
    }
  };

  const forCompoundProducts = productData.filter(product => (product.type == 'simple' || product.type == 'serialized'));

  // Category Options
  const compoundProductOptions = forCompoundProducts.map(item => ({
    value: item.id,
    label: item.name + '[' + item.barcode + ']',
    cost: parseFloat(item.cost),
  }));

  // Category Options
  const categoryOptions = categoryData.map(item => ({
    value: item.id,
    label: item.name,
  }));

  categoryOptions.push({ value: null, label: 'All Categories' })


  // Category Options
  const typeOptions = [
    { value: null, label: 'All Types' },
    { value: 'simple', label: 'simple' },
    { value: 'serialized', label: 'serialized' },
  ]


  const today = new Date().toISOString().split('T')[0];

  const [idRow, setIdRow] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState({ search: '', isActive: 'true', categoryId: null, type: null });

  const [showAddSimple, setShowAddSimple] = useState(false);
  const [showAddCompound, setShowAddCompound] = useState(false);
  const [showAddSerialized, setShowAddSerialized] = useState(false);
  const [showAddProduction, setShowAddProduction] = useState(false);

  const [showEditSimple, setShowEditSimple] = useState(false);
  const [showEditCompound, setShowEditCompound] = useState(false);
  const [showEditProduction, setShowEditProduction] = useState(false);
  const [showEditSerialized, setShowEditSerialized] = useState(false);

  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [productUnits, setProductUnits] = useState([]);
  const [productCompounds, setProductCompounds] = useState([]);
  const [category, setCategory] = useState([]);
  const [type, setType] = useState('');
  const [simpleProduct, setSimpleProduct] = useState({});
  const [compoundProduct, setCompoundProduct] = useState({});
  const [importedData, setImportedData] = useState([]);
  



  const table = 'product';
  const title = 'Products';


  
  const addModal = (type) => {
    if (type == 'simple') {
      setShowAddSimple(!showAddSimple);
    } else
      if (type == 'compound') {
        setShowAddCompound(!showAddCompound);
      } else
        if (type == 'serialized') {
          setShowAddSerialized(!showAddSerialized);
        } else
          if (type == 'production') {
            setShowAddProduction(!showAddProduction);
          }
    setIdRow({});
    setType(type);
  }

  const editModal = (type, record) => {

    if (type == 'simple') {
      setShowEditSimple(!showEditSimple);
      if (!showEditSimple) {
        setIdRow(record);
        setType(record.type);
      }
    } else

      if (type == 'compound') {
        setShowEditCompound(!showEditCompound);
        if (!showEditCompound) {
          setIdRow(record);
          setType(record.type);
        }
      } else

        if (type == 'serialized') {
          setShowEditSerialized(!showEditSerialized);
          if (!showEditSerialized) {
            setIdRow(record);
            setType(record.type);
          }
        } else

          if (type == 'production') {
            setShowEditProduction(!showEditProduction);
            if (!showEditProduction) {
              setIdRow(record);
              setType(record.type);
            }
          }

  }





  const refreshTableFunction = () => {
    setRefreshTable(!refreshTable)
  }

  const recoverModal = (e) => {
    if (!showRecoverModal) {
      setIdRow(e);
    }
    setShowRecoverModal(!showRecoverModal);
  }

  const trashModal = (e) => {
    if (!showTrashModal) {
      setIdRow(e);
    }
    setShowTrashModal(!showTrashModal);
  }

  const deleteModal = (e) => {
    if (!showDeleteModal) {
      setIdRow(e);
    }
    setShowDeleteModal(!showDeleteModal);
  }


  //Excel Data
  const getExcelData = (data) => {

    const transformedData = [];
    data.forEach(item => {

      const newItem = {
        id: item.id,
        barcode: item.barcode,
        category: item.category?.name,
        name: item.name,
        stock: item.stock,
        unit: item.unit,
        cost: item.cost,
        price: item.price,
        type: item.type,
      };

      transformedData.push(newItem);
    });

    return { transformedData };
  };


  let newColumnDefs = [
    { title: 'id', dataIndex: 'id', width: '5%', sorter: (a, b) => a.age - b.age },
    { title: 'barcode', dataIndex: 'barcode' },
    { title: 'category', dataIndex: 'category', render: (category) => (category?.name) },
    { title: 'name', dataIndex: 'name' },
    { title: 'unit', dataIndex: 'unit', width: '8%' },
    { title: 'cost', dataIndex: 'cost', width: '8%', render: numberFormatter },
    { title: 'price', dataIndex: 'price', width: '8%', render: numberFormatter },
    settingData.priceByRatedCurrency && { title: 'rated', dataIndex: 'price', width: '8%',render: (price) => (numberFormatter(price * settingData.rate)) },
    userRole.wholeSale && { title: 'wsPrice', dataIndex: 'wholeSalePrice', width: '8%', render: numberFormatter },
    { title: 'type', dataIndex: 'type', width: '10%' }
  ].filter(Boolean);

  
  useEffect(() => {

    if (filter.isActive == 'true') {
      newColumnDefs.push(
        {
          title: 'Edt',
          width: '5%',
          render: (_, record) => (
            <FiEdit size={16} style={{ cursor: 'pointer', color: '#2E70A5' }} onClick={() => editModal(record.type, record)} />
          ),
        },
        {
          title: 'Del',
          width: '5%',
          render: (_, record) => (
            <BiSolidTrash size={18} style={{ cursor: 'pointer', color: '#e6434f' }} onClick={() => trashModal(record)} />
          ),
        }
      );
    } else {
      newColumnDefs.push({
        title: 'Rec',
        width: '5%',
        render: (_, record) => (
          <FcUndo size={20} style={{ cursor: 'pointer' }} onClick={() => recoverModal(record)} />
        ),
      });
    }
    setColumnDefs(newColumnDefs);

  }, [filter.isActive]);


  const modalFooter = (
    <div>
      {type}
    </div>
  );

  const tableFooter = (
    <></>
  );



  return (
    <div className='card'>

      {isLoading &&
        <Loader />
      }


      {!isLoading &&
        <>
          <div>


            <CompoundProduct showAddModal={showAddCompound} addModal={addModal} showEditModal={showEditCompound}
              editModal={editModal} idRow={idRow} filter={filter} refreshTableFunction={refreshTableFunction} />

            <SimpleProduct showAddModal={showAddSimple} addModal={addModal} showEditModal={showEditSimple}
              editModal={editModal} idRow={idRow} filter={filter} refreshTableFunction={refreshTableFunction} />

            <SerializedProduct showAddModal={showAddSerialized} addModal={addModal} showEditModal={showEditSerialized}
              editModal={editModal} idRow={idRow} filter={filter} refreshTableFunction={refreshTableFunction} />

            <ProductionProduct showAddModal={showAddProduction} addModal={addModal} showEditModal={showEditProduction}
              editModal={editModal} idRow={idRow} filter={filter} refreshTableFunction={refreshTableFunction} />


            <SearchFilter setFilter={setFilter} />
            <IsActiveFilter setFilter={setFilter} />

            <DataFilter setFilter={setFilter} dataOptions={categoryOptions} filterBy={'categoryId'} placeHolder={'All Categories'} />



            <div className='dropDown-btn'>
              <DropdownButton title="Add New" variant="success" className="custom-dropdown-button">
                {settingData.simpleProduct && (<Dropdown.Item onClick={() => addModal('simple')}>Simple</Dropdown.Item>)}
                {settingData.serializedProduct && (<Dropdown.Item onClick={() => addModal('serialized')}>Serialized</Dropdown.Item>)}
                {settingData.variableProduct && (<Dropdown.Item onClick={() => addModal('variable')}>Variable</Dropdown.Item>)}
                {settingData.compoundProduct && (<Dropdown.Item onClick={() => addModal('compound')}>Compound</Dropdown.Item>)}
                {settingData.productionProduct && (<Dropdown.Item onClick={() => addModal('production')}>Production</Dropdown.Item>)}
              </DropdownButton>
            </div>

            <DataFilter setFilter={setFilter} dataOptions={typeOptions} filterBy={'type'} placeHolder={'All Types'} />

            <div className="action-icons">
            
           
          </div>
  
          </div>

           

          <SimpleTable
            filter={filter}
            addModal={addModal}
            table={table}
            title={title}
            columnDefs={columnDefs}
            idRow={idRow}
            setIdRow={setIdRow}
            refreshTable={refreshTable}
            data={data}
            setData={setData}
            tableFooter={tableFooter}
            api={api}
            getExcelData={getExcelData}

          />

          <TrashModal
            showModal={showTrashModal}
            toggleModal={trashModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
            data={{ id: idRow.id }}
          />

          <DeleteModal
            showModal={showDeleteModal}
            toggleModal={deleteModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <RecoverModal
            showModal={showRecoverModal}
            toggleModal={recoverModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />


        </>
      }

    </div>
  )
}

export default Product;
