import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { FcBusinessman, FcKey } from "react-icons/fc";
import GetData from '../init/getData';

function LogIn(props) {
  const { localApi, devSetting } = props;
  const [access, setAccess] = useState(false);
  const [userName, setUserName] = useState('');
  const [pinCode, setPinCode] = useState(['', '', '', '', '', '', '', '', '']); // 9 squares for pin code
  const [usePinCode, setUsePinCode] = useState(devSetting?.isPinCode || false);  
  const [userNames, setUserNames] = useState([]); // For storing fetched usernames
  const [token, setToken] = useState('');
  const [expireyDate, setExpireyDate] = useState('');
  const [loadingEffect, setLoadingEffect] = useState(false);
  let api;

  const serverApi = axios.create({
    baseURL: devSetting?.serverLink,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (devSetting?.isServer || userName === 'dev') {
    api = localApi;
  } else {
    api = serverApi;
  }


  const handlePinCodeChange = (index, value) => {
    const newPinCode = [...pinCode];
    newPinCode[index] = value;
    setPinCode(newPinCode);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingEffect(true);
  
    if(!devSetting.isWebApp && userName !== "dev") {
      const getLisence = await checkLisence();
      if (!getLisence) {
        toast.error('License expired');
        setLoadingEffect(false);
        return;
      }
    }
  
    const form = document.getElementById("check_login_form");
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);
  
    if (usePinCode) {
      // Append pinCode as password to the data object
      data.password = pinCode.join(''); // Combine pinCode array into a single string and assign it to password
      console.log("Password sent: ", data.password);
    }
  
    try {
      let res = await api.post("/user/login", JSON.stringify(data));
      if (!res.data.status) {
        toast.error(res.data.message);
        setLoadingEffect(false);
      } else {
        toast.success('Login Successful');
        localApi.defaults.headers['Authorization'] = `Bearer ${res.data.token}`;
        setToken(res.data.token);
        setAccess(true);
        setLoadingEffect(false);
      }
    } catch (error) {
      toast.error('Login failed');
      setLoadingEffect(false);
    }
  };
  

  const checkLisence = async () => {
    let res = await localApi.post("/user/getLicense");
    if (!res.data.status) {
      toast.error(res.data.message);
      return false;
    } else {
      setExpireyDate(res.data.data.date);
      return true;
    }
  };

  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  if (access && token) {
    return <GetData setAccess={setAccess} userName={userName} devSetting={devSetting} api={api} serverApi={serverApi} localApi={localApi} token={token} expireyDate={expireyDate} />;
  }

  return (
    <div style={{ height: '100vh', backgroundColor: '#23576a' }}>
      <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh', backgroundColor: '#23576a' }}>
        <div className="row justify-content-center form-bg-image" style={{ width: 'auto'}}>
          <div className="col-12 d-flex align-items-center justify-content-center" >
            
            <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500" style={{ backgroundColor: '#23576a'}}>
              <div className="text-center text-md-center mb-4 mt-md-0" >
                <h1 className="mb-0 h3">Sign in to our platform</h1>
              </div>


              <form method="POST" id="check_login_form" onSubmit={handleSubmit}>
                {!usePinCode ? (
                  <>
                    <div className="form-group mb-4">
                      <label>User Name</label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          <i className='bx'><FcBusinessman /></i>
                        </span>
                        <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} className="form-control" placeholder="example" id="userName" name="userName" required />
                      </div>
                    </div>

                    <div className="form-group mb-4">
                      <label>Your Password</label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon2">
                          <i className='bx'><FcKey /></i>
                        </span>
                        <input type="password" placeholder="Password" className="form-control" id="password" name="password" required />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                   <div className="form-group mb-4">
                      <label>Select User</label>
                      <select className="form-control" value={userName} onChange={(e) => setUserName(e.target.value)} id="userName" name="userName" required>
                        <option value="admin">Admin</option>
                        <option value="dev">Dev</option>
                      </select>
                    </div>

                    <div className="form-group mb-4">
                       <label>Enter PIN Code</label>
                           <div className="d-flex justify-content-center">
                             <div className="grid-container" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px' }}>
                                 {pinCode.map((digit, index) => (
                                     <input  
                                          key={index}
                                          name = "pinCode"  
                                          id ="oniCode"
                                          type="text"
                                          maxLength="1"
                                          value={digit}
                                          onChange={(e) => handlePinCodeChange(index, e.target.value)}
                                            style={{ width: '40px', height: '40px', textAlign: 'center', fontSize: '24px' }}
                                                  />
                                                ))}
                                 </div>
                                  </div>
                                 </div>
                  </>
                )}

                <div className="d-grid" style={{ textAlign: 'center' }}>
                  <button style={{ backgroundColor: '#23576a', fontWeight: 'bold' }} className="btn btn-primary" type="submit">Sign in
                    {loadingEffect && <span className="spinner-border spinner-border-sm" style={{ marginLeft: '10px' }} role="status" aria-hidden="true"></span>}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div style={{ textAlign: 'center' }}>
        <p style={{ color: 'white' , fontWeight: 'bold'}}>&nbsp; &nbsp;  Copyright @  | By Riser Team</p>
        <p style={{ color: 'white', fontWeight: 'bold' }}>&nbsp; &nbsp;  Whatsapp: +961 78937719 &nbsp; &nbsp;   Email:&nbsp;risersoftware@gmail.com</p>
      </div>
    </div>
  );
}

export default LogIn;