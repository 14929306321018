import React, { useState, useEffect, useContext, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { numberFormatter } from '../../dynamic/numberFormatter';
import { roundedNumberFormatter } from '../../components/formatters/numberFormatter';
import ComponentToPrint from '../print/componentToPrint';

import { DataContext } from '../../init/getData';
import { FcPortraitMode, FcAbout, FcInTransit, FcHome } from "react-icons/fc";
import ReactToPrint from 'react-to-print';
import { datePrintFormatter } from '../../dynamic/dateFormatter';
import { print } from "../../dynamic/print";

import { Button } from 'antd';

const CartModal = (props) => {

  const { userData, settingData, serverApi, localApi, devData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const componentRef = useRef();
  const [printData, setPrintData] = useState(null);


  let api;
  if (!devData?.isServer && !devData?.isCloud) {
    api = serverApi;
  } else {
    api = localApi;
  }



  const { showModal, toggleModal, idRow, refreshTable, showCost } = props;


  const [loadingEffect, setLoadingEffect] = useState(false);

  const cart = idRow?.cart;
  const printCart = () => {

    let delivery = idRow.delivery;
    if (delivery) {
      delivery.value = 1;
    }

    let account = idRow.account;
    if (account) {
      account.value = 1;
    }

    let printData = {
      cart: cart,
      total: idRow.total,
      settingData: settingData,
      discount: idRow.discount,
      userName: userName,
      invoiceId: idRow.id,
      customer: idRow.account,
      delivery: idRow.delivery,
      date: idRow.date,
    }
    print(printData)
  }

  useEffect(() => {
    if (idRow) {
      setPrintData({
        cart: cart,
        total: idRow.total,
        settingData: settingData,
        discount: idRow.discount,
        userName: userName,
        invoiceId: idRow.id,
        customer: idRow.account,
        delivery: idRow.delivery,
        date: idRow.date,
      })
    }
  }, [idRow]);






  const createInvoice = async () => {
    setLoadingEffect(true);

    let res = await api.post(`transaction/createInvoice`, { transactionId: idRow.id });
    if (res.data.status) {
      toast.success(res.data.message);
      refreshTable();
      toggleModal();
    } else {
      toast.error(res.data.message);
    }
    setLoadingEffect(false);
  }

  return (
    <Modal show={showModal} backdrop="static" size='xl' onHide={toggleModal}>
      <Modal.Header style={{ background: "#39aaa5", display: "flex", justifyContent: "center" }}>
        <div>
          <Modal.Title style={{ color: "white" }}> Transaction ID :  {idRow.id}</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>

        <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '4%', marginTop: '2%' }}>
          {idRow.account && (
            <div className='card' style={{ alignItems: 'center', textAlign: 'center', width: '40%' }}>
              <FcPortraitMode size={45} />
              <h5>account: {idRow.account?.name}</h5>
              <h6>Phone: {idRow.account?.phone}</h6>
            </div>
          )}

          <div className='card' style={{ alignItems: 'center', textAlign: 'center', width: '40%' }}>
            <FcAbout size={45} style={{ marginBottom: '1%' }} />
            <h6>Created At: {datePrintFormatter(idRow.createdAt)}</h6>
            <h6>Created By: {idRow.created ? idRow.created?.name : ''}</h6>
          </div>

          <div className='card' style={{ alignItems: 'center', textAlign: 'center', width: '40%' }}>
            <FcHome size={45} color={'#2eb85c'} style={{ marginBottom: '1%' }} />
            <h6>WareHouse: {idRow.wareHouse?.name}</h6>
            <h6>WareHouse ID: {idRow.wareHouseId}</h6>
          </div>

          {idRow.deliveryId && (
            <div className='card' style={{ alignItems: 'center', textAlign: 'center', width: '40%' }}>
              <FcInTransit size={45} />
              <h5>{devData.deliveryName}: {idRow.delivery?.name}</h5>
              <h6>Phone: {idRow.delivery?.phone}</h6>
            </div>
          )}
        </div>


        <table style={{ width: '100%', borderCollapse: 'collapse', borderColor: '#39aaa5' }} className="table ">

          <thead style={{ background: '#39aaa5', color: 'white', lineHeight: 0.5 }}>
            <tr>
              <th style={{ width: '30%', textAlign: 'left' }}>Category</th>
              <th style={{ width: '30%', textAlign: 'left' }}>Item</th>

              {settingData.serializedProduct &&
                <th style={{ width: '20%', textAlign: 'left' }}>Serial</th>
              }
              <th style={{ width: '10%', textAlign: 'left' }}>{showCost ? 'Cost' : 'Price'}</th>
              <th style={{ width: '10%', textAlign: 'left' }}>Discount</th>
              <th style={{ width: '10%', textAlign: 'left' }}>Net</th>
              <th style={{ width: '10%', textAlign: 'left' }}>Qnty</th>
              <th style={{ width: '10%', textAlign: 'left' }}>Total</th>
            </tr>
          </thead>


          <tbody>
            {cart && cart.map((item) => (
              <>
                <tr key={item.id}>
                  <td style={{ width: '30%', textAlign: 'left' }}>{item.category}</td>
                  <td style={{ width: '30%', textAlign: 'left' }}> {item.name}</td>
                  {settingData.serializedProduct &&
                    <td style={{ width: '20%', textAlign: 'left' }}>{item.serial}</td>
                  }
                  <td style={{ width: '10%', textAlign: 'center' }}>
                    {showCost ? numberFormatter(item.cost, 2) : numberFormatter(item.price, 2)}</td>

                  <td style={{ width: '10%', textAlign: 'center' }}>{item.discount}</td>

                  <td style={{ width: '10%', textAlign: 'center' }}>
                    {settingData.isRated ? roundedNumberFormatter(item.netPrice * idRow.rate, 2) : numberFormatter(item.netPrice, 2)}</td>

                  <td style={{ width: '10%', textAlign: 'center' }}>x{item.quantity}</td>
                  <td style={{ width: '10%', textAlign: 'center' }}>
                    {settingData.isRated ? roundedNumberFormatter(item.totalPrice * idRow.rate, 2) : numberFormatter(item.totalPrice, 2)}</td>
                </tr>

                {item.details &&
                  <tr key={item.id + 9999} >
                    <p style={{ color: 'orange', marginLeft: '5%' }}>
                      Details: {item.details}
                    </p>
                  </tr>
                }
              </>
            ))}
          </tbody>

        </table>

        {cart &&
          <div style={{ marginTop: '5%', marginBottom: '10px', fontWeight: 'bold', marginLeft: '35%', width: '30%', borderTop: '2px solid #dfdfdf' }}>

            {/* Total */}
            {!settingData.isRated &&
              <div>
                <p style={{ fontWeight: 'bold', display: 'inline-block', margin: 0 }}>Total:</p>
                <p style={{ marginLeft: 5, float: 'right', margin: 0 }}>{numberFormatter(cart.reduce((total, item) => total + parseFloat(item.totalPrice), 0), 2)}{settingData.currency}</p>

              </div>
            }
            {settingData.isRated &&
              <div>
                <p style={{ fontWeight: 'bold', display: 'inline-block', margin: 0 }}>Total:</p>
                <p style={{ marginLeft: 5, float: 'right', margin: 0 }}>
                  {numberFormatter(cart.reduce((total, item) => total + parseFloat(item.totalPrice), 0), 2)}{settingData.currency}&nbsp;
                  || {roundedNumberFormatter(cart.reduce((total, item) => total + parseFloat(item.totalPrice), 0) * idRow.rate, 2)}{settingData.ratedCurrency}
                </p>
              </div>
            }

            {/* Discount */}
            <p style={{ fontWeight: 'bold', display: 'inline-block', margin: 0 }}> Discount: </p>
            <p style={{ marginLeft: 5, float: 'right', margin: 0 }}>{idRow.discount}%</p>

            {/* TVA */}
            {idRow.tva > 0 &&
              <div>
                <p style={{ fontWeight: 'bold', display: 'inline-block', margin: 0, color: 'red' }}> TVA: </p>
                <p style={{ marginLeft: 5, float: 'right', margin: 0, color: 'red' }}>{idRow.tva}%</p>
              </div>
            }

            {/* Net Total */}
            {!settingData.isRated &&
              <div>
                <p style={{ fontWeight: 'bold', display: 'inline-block', margin: 0, color: 'green' }}>NetTotal: </p>
                <p style={{ marginLeft: 5, float: 'right', margin: 0, color: 'green' }}>{numberFormatter(idRow.total, 2)} {settingData.currency}</p>
              </div>
            }
            {settingData.isRated &&
              <div>
                <p style={{ fontWeight: 'bold', display: 'inline-block', margin: 0, color: 'green' }}>NetTotal: </p>
                <p style={{ marginLeft: 5, float: 'right', margin: 0, color: 'green' }}>{numberFormatter(idRow.total, 2)} {settingData.currency}&nbsp;
                  || {roundedNumberFormatter(idRow.total * idRow.rate, 2)} {settingData.ratedCurrency}</p>
              </div>
            }
          </div>
        }


        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {idRow.isOrder &&
            <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', paddingLeft: '180px' }}>
              <Button type="primary" size="large" loading={loadingEffect}
                style={{ backgroundColor: '#008000' }}
                onClick={createInvoice}>Create Invoice</Button>
            </div>
          }
          <div style={{ display: 'flex', gap: '10px' }}>
            <button type="button" className="btn btn-secondary" style={{ backgroundColor: '#1f2937' }} onClick={toggleModal}>Close</button>
            {!devData.isWebApp &&
              <button type="button" style={{ backgroundColor: '#1d7790' }} className="btn btn-primary" onClick={printCart}>Print</button>
            }
            {devData.isWebApp &&
              <ReactToPrint
                trigger={() => <button type="button" style={{ backgroundColor: '#1d7790' }} className="btn btn-primary">Print</button>}
                content={() => componentRef.current}
              />
             }
          </div>
        </div>

        {/* Component to print */}
        <div style={{ display: 'none' }}>

          {printData &&
            <div style={{ display: 'block' }}>
              <ComponentToPrint
                ref={componentRef}
                devData={devData}
                settingData={settingData}
                userName={userName}
                printData={printData}
              />
            </div>
          }


        </div>



      </Modal.Body>

    </Modal>
  );
};

export default CartModal;
