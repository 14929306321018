import React, { useState, useEffect, useContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Switch, Spin, Button } from 'antd';
import { link } from '../../dynamic/api';


import Select from 'react-select';

import { DataContext } from '../../init/getData';

function AppMode() {

  const { api, userData, refDevData, devData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const [isLoading, setIsLoading] = useState(true);
  const [devDataObject, setDevDataObject] = useState(null);



  useEffect(() => {
    const fetchData = async () => {
      try {
        await refDevData();
        setIsLoading(false)
      } catch (error) {
        console.error('Error Getting Data');
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setDevDataObject(devData);
  }, [devData]);




  const handleSubmit = async (e) => {
    e.preventDefault();


    const settings = devDataObject;

    if(devDataObject.isServer){
      settings.isCloud = false;
    }


    const res = await api.post(`/dev/edit`, JSON.stringify({settings}))
    if (res.data.status) {
      toast.success(res.data.message);
      refDevData();
    }
    else {
      toast.error(res.data.message);
    }

  }


  return (
    <div className="card" style={{ userSelect: 'none', height: 'auto', display: 'flex', flexDirection: 'row' }}>


      <Spin size="large" spinning={isLoading} style={{ margin: 'auto' }} />


      {!isLoading && devDataObject &&

        <Form onSubmit={handleSubmit} style={{ width: '45%', margin: '0', marginTop: '30px' }} id='settingForm'>


          {/* is Web App */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3} className="align-self-center">Web App</Form.Label>
            <Col sm={9} className="align-self-center">
              <Switch checked={devDataObject?.isWebApp} onChange={() => setDevDataObject({ ...devDataObject, isWebApp: !devDataObject?.isWebApp })} />
            </Col>
          </Form.Group>


          {/* is Server */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3} className="align-self-center">is Server</Form.Label>
            <Col sm={9} className="align-self-center">
              <Switch checked={devDataObject?.isServer} onChange={() => setDevDataObject({ ...devDataObject, isServer: !devDataObject?.isServer })} />
            </Col>
          </Form.Group>

          {/* is Pin Code */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3} className="align-self-center">is PinCode</Form.Label>
            <Col sm={9} className="align-self-center">
              <Switch checked={devDataObject?.isPinCode} onChange={() => setDevDataObject({ ...devDataObject, isPinCode: !devDataObject?.isPinCode })} />
            </Col>
          </Form.Group>    


          {!devDataObject?.isServer &&
            <>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>Server Link</Form.Label>
                <Col sm={9}>
                  <Form.Control type="text" step='any' placeholder="http.. " style={{ width: '70%' }}
                  value={devDataObject?.serverLink}
                  onChange={(e) => setDevDataObject({ ...devDataObject, serverLink: e.target.value })}
                  />
                </Col>
              </Form.Group>



              {/* is offline */}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3} className="align-self-center">cloud Server</Form.Label>
                <Col sm={9} className="align-self-center">
                  <Switch checked={devDataObject?.isCloud} onChange={() => setDevDataObject({ ...devDataObject, isCloud: !devDataObject?.isCloud })} />
                </Col>
              </Form.Group>
            </>
          }

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>My Local Link</Form.Label>
            <Col sm={9}>
              <Form.Control type="text" value={link} step='any' placeholder="http://example.com:5001 " style={{ width: '70%' }} readOnly/>
            </Col>
          </Form.Group>


          <Form.Group as={Row} className="mb-3">
            <Col sm={{ span: 10, offset: 2 }}>
              <Button type="submit" className="btn btn-primary" onClick={handleSubmit}>Save</Button>
            </Col>
          </Form.Group>



        </Form>

      }

    </div>
  );
}

export default AppMode;
